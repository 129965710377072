

@media screen and (max-width: 768px) {
  .plaved-jumbotron .container-adsk {
    
    min-height: 250px;
  }
}

@media (max-width: 768px) {
  .fullscreen .plaved-jumbotron .container-adsk {
      height: 100vh;
      width: 100vw;
  }
}

@media (max-width: 768px) {
  .explode .plaved-jumbotron .range-style {
      width: 50%;
  }
}



/*Iphone 5 & 5s*/
 /* STYLES GO HERE */

/*
.plaved-jumbotron .plaved-btn, .explode-btn, .explode-motion-btn, .rotate-motion-btn, .reset-btn, .properties-btn{
  font-size: 1.2em !important;
  height: 35px !important;
  width: 35px !important;
}

.properties-btn{
  right: 40px !important;
}

.explode-btn {
  right: 80px !important;
}

.explode-motion-btn {
  right: 120px !important;
}

.rotate-motion-btn {
  right: 160px !important;
}

.reset-btn{
  right: 200px !important;
}*/



/*Iphone 6 plus*/
/*@media (min-width : 414px) 
and (max-width : 736px)*/

@media screen and (max-width: 736px) { /* STYLES GO HERE */


  .fullscreen .plaved-jumbotron {
    height: 100vh;
  }
  
 

/*  Suppose to handle Safari browser
  ::i-block-chrome, .plaved-jumbotron .container-adsk{
    top: -50px;
  }*/

 .plaved-jumbotron .plaved-logo {
    top: 30px !important;
 }
}

/* CSS for non mobile devices */

.plaved-jumbotron {
  background: #EDEDED;
  margin: 0;
  
  position: relative;
}

.plaved-jumbotron .container-adsk {
  height: 100vh;
  min-height: 200px;
  position: relative;
  transition: all 300ms ease;
}

.fullscreen .plaved-jumbotron .container-adsk {
  height: 100vh;
  width: 90vw;
}


.plaved-jumbotron .plaved-logo {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 2;
}

.plaved-jumbotron .plaved-logo img {
  min-width: 175px;
  width: 18vw !important;
}


.plaved-jumbotron .plaved-viewer {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.botonera-izda {
  display: flex;
  flex-direction: column;
  z-index: 999;
  position: absolute;
  left: 34px;
  display: flex;
justify-content: space-between;
  height: 50vh;
  width: 100px;
}

.botonera-izda button {
  width: 50px;
  height: 50px;
  border-radius: 50px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}
.botonera-izda button i {
  width: 75%;
  height: 75%px;
  color: black;
  z-index: 1000;
}

.plaved-jumbotron .plaved-btn {
  background-color: #EE8822;
  border-radius: 50%;
  border: none;
  
  color: white;
  font-size: 2em;
  height: 55px;
  line-height: 1.8;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 300ms ease;
  width: 55px;
  z-index: 2;
}

.plaved-jumbotron .plaved-btn:focus {
  outline: none;
}

.plaved-jumbotron .plaved-btn:active {
  background-color: #b4610e;
}

.fullscreen .plaved-jumbotron .plaved-btn {
  bottom: 40px;
}

.plaved-jumbotron .explode-btn, .explode-motion-btn, .rotate-motion-btn, .reset-btn, .properties-btn {
  background-color: rgba(135,135,135, 0.6);
  border-radius: 50%;
  border: none;
  bottom: -48px;
  color: white;
  font-size: 2em;
  height: 55px;
  line-height: 1.8;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 55px;
  z-index: 2;
  visibility: hidden;
  outline: none;
}

.plaved-jumbotron .properties-table {
  visibility: hidden;
}

.fullscreen .plaved-jumbotron .properties-table {
  visibility: visible;
}

.properties-btn{
  right: 60px;
}

.explode-btn {
  right: 120px;
}

.explode-motion-btn {
  right: 180px;
}

.rotate-motion-btn {
  right: 240px;
}

.reset-btn{
  right: 300px;
}

.fullscreen .plaved-jumbotron .properties-btn {
  bottom: 40px;
  visibility: visible;
}

.fullscreen .plaved-jumbotron .explode-btn {
  bottom: 40px;
  visibility: visible;
}

.fullscreen .plaved-jumbotron .explode-motion-btn {
  bottom: 40px;
  visibility: visible;
}

.fullscreen .plaved-jumbotron .rotate-motion-btn {
  bottom: 40px;
  visibility: visible;
}

.fullscreen .plaved-jumbotron .reset-btn {
  bottom: 40px;
  visibility: visible;
}

.plaved-jumbotron .btn--active, .expbtn--active, .rotbtn--active {
  background-color: rgba(53,102,204, 0.9);
}

.plaved-jumbotron .btn--deactive, .expbtn--deactive, .rotbtn--deactive {
  background-color: rgba(135,135,135, 0.6);
}

.plaved-jumbotron .range-style {
  background-color: rgba(135,135,135, 0.8);
  bottom: -48px;
  color: white;
  font-size: 2em;
  height: 55px;
  line-height: 1.8;
  padding: 0;
  position: absolute;
  text-align: center;
  transition: all 300ms ease;
  width: 55px;
  right: 0;
  z-index: 2;
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.explode .plaved-jumbotron .range-style {
  bottom: 100px;
  width: 24%;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 300ms;
}


.imageContainer {
  display: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.ptro-holder-wrapper {
  z-index: 9999 !important;
}
.forge-spinner  {
  max-width: 40vw;
  content: url("/images/plaved-landscape-logo.png");
}
.exitIcon {
  background-size: contain;
 
 
}
.plavedLogo {
  height: 9vh;
}
.visorPlanos {
  height: 90vh;
  position: relative;
  width: 100vw;
 
}
.photoIcon {
  background-size: contain;
  
}


.react-docking-panel {
  height: 50vh;
  width: 50vw;
  resize: auto;
  left: 10px;
  top: 10px;
background-color: white !important;
 
} 

.reactPanelModalButton {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);

}

.reactPanelModalContentContainer {
  display: flex;
  width: 100%;
  height: 90%;
}

.reactPanelModalImage {
width: 100%;
}
.react-content {
  padding: 10px;
}
.reactPanelModalLeftContainer {
  display: flex;
  flex: 50%;

  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.reactPanelModalRightContainer {

  
  flex-wrap: wrap;
  flex: 50%;
overflow: auto;
  

  height: 100%;
  align-items: center;
  justify-content: center;
  
}
/* The Modal (background) */
.reactPanelModal {
display: none; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 99999; /* Sit on top */
left: 0;
top: 0;
width: 100vw; /* Full width */
height: 100vh; /* Full height */
overflow: auto; /* Enable scroll if needed */
background-color: rgba(0, 0, 0, 0.4);
}
.reactPanelModalActive {
display: block; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 999; /* Sit on top */
left: 0;
top: 0;
width: 100vw; /* Full width */
height: 100vh; /* Full height */
overflow: auto; /* Enable scroll if needed */
background-color: rgba(0, 0, 0, 0.4);
}


a {
color: inherit;
}
a:hover {
color: #7f8ff4;
}

.container-adsk {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.uppercase {
text-transform: uppercase;
}


.form__field {
margin: 20px;
height: 10%;
width: 70%;
background-color: white;
color: black;
font-size: 15px;
box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
border: 0;
outline: 0;
padding: 22px 18px;
}
/* Modal Content/Box */
.reactPanelModalContent {
display: block;
background-color: #fefefe;
margin: 5% auto; /* 15% from the top and centered */
padding: 20px;
border: 1px solid #888;
width: 90vw; /* Could be more or less, depending on screen size */
height: 70%;
}

/* The Close Button */
.close {
color: #aaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}